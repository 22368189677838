@use '@angular/material' as mat;
@import 'https://library-sdb.apps.bancolombia.com/1.0.8/bds.min.css';
@import 'https://library-sdb.apps.bancolombia.com/bds/6.17.5/bds.min.css';
@import "./assets/scss/_variables.scss";
@import "./assets/scss/_spacing.scss";
@import "./assets/scss/_flex.scss";
@import url("./assets/scss/_base.scss");


$admintracking-primary: mat.define-palette($palette-primary);
$admintracking-accent: mat.define-palette($palette-accent, A700, A100, A400);
$admintracking-warn: mat.define-palette(mat.$red-palette);
$custom-typography: mat.define-typography-config($font-family: "Nunito");

$admintracking-theme: mat.define-light-theme((
color: (
  primary: $admintracking-primary,
  accent: $admintracking-accent,
  warn: $admintracking-warn
),
typography: $custom-typography,
));

@include mat.typography-hierarchy($custom-typography);
@include mat.core();
@include mat.core-theme($admintracking-theme);
@include mat.all-component-themes($admintracking-theme);

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-fill .mat-mdc-form-field-flex {
  background-color: transparent;
}

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  height: 100%;
}

body {
  width: 100%;
}

small {
  font-size: 1em !important;
  color: $color-dark-light-2;
  padding: 0 $spacing-xs;
}

h3 {
  font-family: "NunitoBold";
  font-style: normal;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: -0.6px;
  color: $color-text;
  font-size: 1.6em;
}

.sub-title-blue {
  font-family: "NunitoBold";
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
  letter-spacing: -0.45px;
  color: $color-icon-02;
}

.sub-title {
  font-family: "NunitoBold";
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: -0.3px;
  color: $color-text;
}

p {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: $color-text;
}

h1,
h2,
h4,
h5,
h6 {
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1;
  color: $color-dark;
}

.container-customized {
  width: 100%;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  opacity: 0.6;
}

h1,
h2 {
  font-family: "NunitoExtraBold" !important;
}

h4,
h5,
h6 {
  font-family: "NunitoBold" !important;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.8em;
}

h4 {
  font-size: 1.4em;
}

h5 {
  font-size: 1.2em;
}

h6 {
  font-size: 1em;
}

.text-subtitle {
  font-family: "NunitoBold";
  font-style: normal;
  font-stretch: normal;
  line-height: 0.91;
  letter-spacing: -0.6px;
}

.text-center {
  text-align: center;
}

* {
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: -0.38px;
}

.highlight-success {
  color: $color-dark !important;
  font-family: "NunitoBold", sans-serif;
}

.highlight-error {
  color: $color-red !important;
  font-family: "NunitoBold", sans-serif;
}

.mat-mdc-form-field-text-suffix {
  top: 0 !important;
  width: auto !important;
  font-family: "NunitoBold", sans-serif;
  font-weight: 700;
  text-align: right;
  color: $color-dark;
}

.mat-mdc-form-field {
  margin: $spacing-xxs 0;
}

::ng-deep .mat-form-field-legacy {
  padding: 0 !important;
  width: 100% !important;
}

.container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
}

.box {
  background-color: white;
  border: 1px solid #dedfe0;
  border-radius: $spacing-xs;
  box-shadow: 0px 1px 8px 0px $color-shadow-light;
  display: flex;
  flex-flow: column nowrap;
  margin: $spacing-sm 0;

  header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    svg {
      margin: 0 $spacing-xxs 0 0;
    }

    .box-options {
      svg {
        margin: 0 0 0 $spacing-xs;
        color: $color-dark-light;
        font-size: 1.2em;
      }
    }
  }
  .mat-mdc-form-field-flex {
    border-radius: 4px 4px 0 0;
    padding: 0.5em 0.75em 0 0.75em;
  }
}

.box-body,
.box-header {
  padding: $spacing-xs $spacing-sm;

  h5,
  h6 {
    margin: $spacing-xxs;
  }
}

.box-header {
  border-bottom: 1px solid #dedfe0;
}

.box-body {
  width: auto;
  flex-grow: 2;
}

.box-header {
  max-height: 60px;
}

.box-info {
  box-shadow: 0px 5px 5px -3px $color-shadow,
    0px 8px 10px 1px $color-shadow-light, 0px 3px 14px 2px $color-shadow-light;
}

.box-footer {
  width: 100%;
  border-top: 1px solid #dedfe0;
  color: $color-dark-light;
  font-family: "NunitoBold", sans-serif;
  padding: $spacing-sm;
  width: auto;
  display: flex;
  justify-content: space-between;
}

::ng-deep .mat-form-field-appearance-legacy {
  padding: 0 !important;
  width: 100% !important;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.btn {
  font-style: normal;
  font-stretch: normal;
  line-height: 0.98;
  font-family: "NunitoBold", Arito, sans-serif;
  padding: 0.5em $spacing-md;
  border-radius: $spacing-md;
  text-decoration: none;
  margin: $spacing-md auto;
  font-size: 1em;
  max-height: 38px;
  text-transform: uppercase;
  transition: 0.2s;
  cursor: pointer;
  margin: $spacing-xxs;
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem $color-shadow;
}

.btn:hover {
  box-shadow: 0 0 4px 0 $color-shadow, 0 0px 4px 0 $color-shadow-light;
  transform: scale(1.01);
}

.btn-primary {
  background-color: $color-primary;
  border: none;
  color: $color-dark;
}

.btn-secondary {
  background-color: $color-bg-transparent;
  border: 1.5px solid $color-secondary;
  color: $color-secondary;
}

.btn-tertiary {
  background: none;
  border: 0;
  cursor: pointer;
  margin: $spacing-sm 0 !important;
  color: $color-dark-light;
  text-transform: uppercase;
  font-family: "NunitoBold", "Nunito", sans-serif;

  svg {
    font-size: 1em;
    border-radius: 50%;
    color: $color-primary;
  }
}

.m-auto {
  margin: auto;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Nunito", "Helvetica Neue", sans-serif;
}

mat-form-field {
  width: 100%;
}

a {
  cursor: pointer;
}

.animated-scale {
  transition: $default-time;
}

.animated-scale:hover {
  transform: scale(1.1);
}

.tracking-toast-bar-success,
.tracking-toast-bar-warning,
.tracking-toast-bar-error {
  min-width: 368px;
  max-width: 80vw !important;
  font-family: "NunitoBold", sans-serif !important;
  font-weight: 700;
  border-radius: 15px;
  background: rgba(255, 255, 255, 1);
  color: $color-dark;

  .mat-mdc-simple-snack-bar {
    justify-content: center;
    align-items: center;
  }

  .mat-mdc-button::before,
  .mat-mdc-simple-snack-bar::before {
    font-family: "Font Awesome 5 Free", sans-serif !important;
    font-weight: 400 !important;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-wrapper {
    display: none;
  }

  .mat-mdc-button::before {
    content: "\f057" !important;
    color: $color-dark-light;
    padding: 0 0 0 $spacing-xs;
    font-size: 1.4em !important;
  }

  .mat-mdc-simple-snack-bar::before {
    font-size: 1.8em !important;
    padding: 0 $spacing-xs 0 0;
  }
}

.tracking-toast-bar-success {
  .mat-mdc-simple-snack-bar::before {
    content: "\f058" !important;
    color: $color-success;
  }
}

.tracking-toast-bar-warning {
  .mat-mdc-simple-snack-bar::before {
    content: "\f05a" !important;
    color: $color-primary;
  }
}

.tracking-toast-bar-error {
  .mat-mdc-simple-snack-bar::before {
    content: "\f057" !important;
    color: $color-red;
  }
}

