@font-face {
    font-family: 'NunitoBold';
    src: url('../../assets/fonts/Nunito-Bold.woff2') format('woff2'), url('../fonts/Nunito-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'NunitoExtraBold';
    src: url('../../assets/fonts/Nunito-ExtraBold.woff2') format('woff2'), url('../../assets/fonts/Nunito-ExtraBold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'OpenSansRegular';
    src: url('../../assets/fonts/OpenSans-Regular.woff2') format('woff2'), url('../../assets/fonts/OpenSans-Regular.woff') format('woff');
    font-weight: 800;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Nunito';
    src: url('../../assets/fonts/Nunito.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: 'OpenSans';
    src: url('../../assets/fonts/OpenSans.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  