// Tiempos
$default-time: 0.3s;
$long-time: 0.3s;

// Texto
$color-primary: hsl(49, 100%, 50%);
$color-secondary: hsl(211, 100%, 28%);
$color-dark: #2e2e2e;
$color-dark-light: #575757;
$color-dark-light-2: #808285;
$color-white: #fafafa;
$color-red: #FA5E5B;
$color-red-dark: rgb(255, 6, 1);
$color-disabled: #747779;
$color-text: #292929;

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: #fafafa;

$color-success: #16c98d;

$palette-primary: (
  50: $color-primary,
  100: $color-primary,
  200: $color-primary,
  300: $color-primary,
  400: $color-primary,
  500: $color-primary,
  600: $color-primary,
  700: $color-primary,
  800: $color-primary,
  900: $color-primary,
  A100: hsl(49, 100%, 77%),
  A200: hsl(49, 99%, 66%),
  A400: hsl(49, 99%, 62%),
  A700: hsl(49, 99%, 59%),
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text
  )
);

$palette-accent: (
  50: $color-primary,
  100: $color-primary,
  200: $color-primary,
  300: $color-primary,
  400: $color-primary,
  500: $color-primary,
  600: $color-primary,
  700: $color-primary,
  800: $color-primary,
  900: $color-primary,
  A100: hsl(49, 100%, 77%),
  A200: hsl(49, 99%, 66%),
  A400: hsl(49, 99%, 62%),
  A700: hsl(49, 99%, 59%),
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text
  )
);

// Sombras
$color-shadow: rgba(0, 0, 0, 0.21);
$color-shadow-light: rgba(0, 0, 0, 0.11);

// Íconos
$color-icon-01: #ffcf01;
$color-icon-02: #00448d;
$color-icon-03: #454648;
$color-icon-04: #ffffff;
$color-icon-05: #fa5e5b;
$color-icon-06: #16c98d;

// Botones
$color-btn-01: #ffcf01;
$color-btn-02: #ffc20e;

// Fondos
$color-bg-70: #58595b;
$color-bg-60: #6d6e71;
$color-bg-30: #9e9e9e;
$color-bg-20: #bdbdbd;
$color-bg-10: #d1d3d4;
$color-bg-05: #e6e7e8;
$color-bg-01: #f7f7f7;
$color-bg-00: #ffffff;
$color-bg-transparent: rgba(0, 0, 0, 0);
